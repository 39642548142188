import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          usados.com.py
        </p>
        <a
          className="App-link"
          href="http://bit.ly/3g1z3Nq"
        >
          Ver catálogo antiguo
        </a>
        <br/>
        <br/>
        <a className="App-link" href="http://bit.ly/3Aac5dS">
          Comprar este dominio
        </a>
      </header>
    </div>
  );
}

export default App;
